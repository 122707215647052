<template>
  <v-app>
    <v-app-bar app flat>
      <div class="d-flex align-center">
        <v-img
          contain
          width="200"
          src="./assets/logo-dark-peq.png"
          transition="scale-transition"
        />
      </div>

      <v-spacer></v-spacer>
      <div v-if="$vuetify.breakpoint.smAndUp">
        <v-btn
          class="mr-3"
          outlined
          @click="$vuetify.goTo('#home', options)"
          text
          >Home</v-btn
        >
        <v-btn
          class="mr-3"
          outlined
          @click="$vuetify.goTo('#sobre', options)"
          text
          >Sobre</v-btn
        >
        <v-btn
          class="mr-3"
          outlined
          @click="$vuetify.goTo('#sistemas', options)"
          text
          >Sistemas</v-btn
        >
        <v-btn
          class="mr-3"
          outlined
          @click="$vuetify.goTo('#contatos', options)"
          text
          >Contato</v-btn
        >
      </div>
    </v-app-bar>

    <v-main>
      <Home />
    </v-main>
  </v-app>
</template>

<script>
//<!-- <v-switch v-model="$vuetify.theme.dark" color="error" hide-details /> -->
import Home from "./components/Home";

export default {
  name: "App",

  components: {
    Home,
  },

  data() {
    return {
      options: {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      },
    };
  },
};
</script>
