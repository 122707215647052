<template>
  <div class="home" id="home">
    <!-- max-height="700px" -->
    <v-img
      dark
      width="100%"
      :height="$vuetify.breakpoint.mobile ? '400px' : '920px'"
      src="@/assets/fundo7.jpg"
    >
      <!-- <v-layout fill-height align-center> -->
      <v-container>
        <v-row align="center" justify="center" class="white--text">
          <v-col cols="12">
            <div
              :class="
                $vuetify.breakpoint.mobile
                  ? 'mt-5 text-h5 text-center font-weight-bold'
                  : 'mt-15 text-h3 text-center font-weight-bold'
              "
            >
              Precisando de um ERP para sua empresa?
            </div>
            <div
              :class="
                $vuetify.breakpoint.mobile
                  ? 'subtitle-1 text-center'
                  : 'text-h4 text-center'
              "
            >
              Nosso software conta com todos os recursos que sua empresa
              necessita.
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- </v-layout> -->
    </v-img>
    <v-container class="mt-5">
      <v-layout wrap>
        <v-flex xs12>
          <div
            :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
            id="sobre"
          >
            <br />
            Sobre a
            <span
              :class="
                $vuetify.breakpoint.mobile ? 'font-logo-mobile' : 'font-logo'
              "
            >
              <span class="red--text">X</span>Software</span
            >
          </div>
        </v-flex>
        <v-flex xs12 mt-5>
          <div class="body-1 mt-3">
            <p>
              Uma empresa inovadora e criativa, trazendo ao mercado um software
              completo que atende aos segmentos de Indústria, Comércio e
              Serviços, simplificando os controles e processos.
            </p>
            <p>
              Agilidade e comprometimento, nosso suporte é diferenciado e
              elogiado.
            </p>
            <p>
              A 14 anos no mercado, nossa missão é fornecer software de
              qualidade e confiabilidade, proporcionando o crescimento e
              bem-estar dos clientes, auxiliando na tomada de decisão com mais
              facilidade.
            </p>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container class="mt-5">
      <v-layout wrap my-5>
        <v-flex xs12>
          <div
            :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
            id="sistemas"
          >
            <br />
            <span>Sistemas</span>
          </div>
          <br />
          <v-row>
            <v-col
              v-for="imagem in imagens"
              :key="imagem.source"
              :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
            >
              <v-card class="px-5" min-height="600">
                <v-card-title>
                  {{ imagem.titulo }}
                </v-card-title>

                <v-divider class="mb-4"></v-divider>

                <v-img contain max-height="320" :src="logo(imagem.source)">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <v-card-text class="text-justify">
                  <div>{{ imagem.texto }}</div>
                </v-card-text>

                <v-card-actions>
                  <div class="text-center">
                    <v-row justify="center">
                      <v-dialog
                        v-model="imagem.dialog"
                        v-if="
                          imagem.titulo === 'XComercial' ||
                            imagem.titulo === 'Concreteiras'
                        "
                        fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn text color="error" v-bind="attrs" v-on="on">
                            Detalhes
                          </v-btn>
                        </template>
                        <v-card>
                          <v-toolbar flat>
                            <v-btn icon @click="imagem.dialog = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title class="red--text"
                              >Detalhes</v-toolbar-title
                            >
                          </v-toolbar>
                          <XComercial v-if="imagem.titulo === 'XComercial'" />
                          <Concreteira
                            v-if="imagem.titulo === 'Concreteiras'"
                          />
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </div>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container class="mt-5">
      <div
        :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
        id="contatos"
      >
        <br />
        <span>Contato</span>
      </div>
      <br />
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '4'">
          <v-list two-line elevation="3">
            <v-list-item>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mobile ? '50' : '70'"
              >
                <img src="@/assets/avatar-xs.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>XSoftware</v-list-item-title>
                <v-list-item-subtitle>(43) 3328-1790</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '4'">
          <v-list two-line elevation="3">
            <v-list-item>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mobile ? '50' : '70'"
              >
                <v-img src="@/assets/avatar-alexandre.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Carlos Alexandre Pereira</v-list-item-title>
                <v-list-item-subtitle>(43) 98409-9805</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile ? '12' : '4'">
          <v-list two-line elevation="3">
            <v-list-item>
              <v-list-item-avatar
                :size="$vuetify.breakpoint.mobile ? '50' : '70'"
              >
                <v-img src="@/assets/avatar-armando.png" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Armando Boza Gonçalves</v-list-item-title>
                <v-list-item-subtitle>(43) 98409-9735</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <span class="font-logo-footer"
          ><span class="red--text">X</span>Software</span
        >
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import XComercial from "./XComercial.vue";
import Concreteira from "./Concreteira.vue";

export default {
  components: {
    XComercial,
    Concreteira,
  },
  data() {
    return {
      contatos: [
        { nome: "Armando", email: "armando.boza@gmail.com" },
        { nome: "Alexandre", email: "alexandrepereira1981@gmail.com" },
      ],
      imagens: [
        {
          dialog: false,
          source: "pic02.png",
          titulo: "XComercial",
          texto:
            "Nosso ERP completo, emissão de NFe, NFCe, NFSe, MDFe e CTe, importação de NFe e CTe online, cadastros diversos, PDV, controle de estoque, orçamentos, cotações, manutenção de frota, controle financeiro, emissão de boleto bancário, dashboard, DRE, relatórios diversos, usuários com controle de perfil de acesso, integração com SPC, Mercos, Magento e Comtele.",
        },
        {
          dialog: false,
          source: "pic01.png",
          titulo: "Concreteiras",
          texto:
            "Se sua empresa é uma CONCRETEIRA nós temos uma solução completa para você, com controle de contratos/obras, traços, dosagem, umidade, matérias primas, ordem de serviço, integração com automação, controle de frota, faturamento por agrupamento de OS, corpos de prova e laudos de rompimento, relatórios diversos e muito mais.",
        },
        {
          dialog: false,
          source: "rastreamento.png",
          titulo: "Rastreamento",
          texto:
            "Controles personalizados para empresas de rastreamento, controle de contratos com impressão de requerimentos, contratos e termos em modelo do OpenOffice, Ordem de serviço (instalaçao, retirada, guincho e mais), fila de atendimento, cobrança e vários outros recursos.",
        },
        {
          dialog: false,
          source: "pic03.png",
          titulo: "Dashboard",
          texto:
            "Dashboard completo, diversos gráficos de vendas, fluxo de caixa, estatísticas, produção da usina, ticket-médio, produtividade de contratos, curva-abc, vendas por geo-localização e muito mais.",
        },
        {
          dialog: false,
          source: "pic05.png",
          titulo: "Mobile",
          texto:
            "Desenvolvemos integrações mobile conforme necessidade do cliente, utilizamos as mais novas tecnologias de desenvolvimento do mercado.",
        },
      ],
    };
  },
  methods: {
    logo(name) {
      return require("../assets/" + name);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.font-logo-footer {
  font-family: "Righteous", cursive;
  font-size: 20px;
}
.font-logo-mobile {
  font-family: "Righteous", cursive;
  font-size: 30px;
}
.font-logo {
  font-family: "Righteous", cursive;
  font-size: 40px;
}
</style>
